<template>
  <div class="job-assignment">
    <navbar />
    <breadcrumbs></breadcrumbs>
    
    <div class="container">
      <all-job-assignments/>
    </div>

  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import AllJobAssignments from '@/components/AllJobAssignments.vue'

export default {
  components: {
    Navbar,
    AllJobAssignments,
  }
}
</script>

<style>

</style>
